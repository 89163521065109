.service-obj {
    padding: 0 !important;
    min-height: 470px;
    position: relative;
    overflow: hidden;
}
.live-clone{
    padding: 0 !important;
    min-height: 435px;
    position: relative;
    overflow: hidden;
}
.service-container{
    height: 100%;
    position: relative;
    margin: auto;
    will-change: transform;
    flex-direction: column;
}
.service-banner-img{
    height: 360px;
}
.ser-card-slide-text{
    text-align: left;
    font-size: .9rem;
}
.live-clone-title-wrapper {
    margin: 0 auto 64px;
    text-align: center;
}

.live-clone-title-wrapper {
    margin: 0 auto 48px
}

.live-clone-title-wrapper>h1 {
    font-size: 1.6rem;
    color: #314659;
}
.live-clone-title-wrapper>h6{
    color: #37474f;
}
.live-clone-text{
    font-size: 1rem;
    text-align: left;
    color: #607d8b;
}
.live-clone-card-img{
    width: 38%;
}
.live-clone-card-text{
    color: #263238;
    text-align: right;
}
.live-clone-card-title{
    font-weight: 600;
}
.server-obj{
    text-align: center;
}
.audio-style{
    width: 100%;
    height: 30px;
    display: block;
    margin-top: 30px;
}
.voice-row div,.voice-row div>div{
    border: none;
    box-shadow: none !important;
}
.voice-row{
    margin: 10px 0;
    border-bottom: 1px dotted #1565c0;
}
@media screen and (max-width: 767px){
    .ser-card-slide-text,.live-clone-text {
        font-size: 1.2rem;
    }
    .voice-col{
        margin: 10px 0;
    }
}
